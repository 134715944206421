'use client';

import { useId, useState } from 'react';

import cx from 'classnames';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import CheckIcon from '~/ui/assets/icons/check.svg';
import { Event, trackEvent } from '~/ui/components/analytics';
import typographyStyles from '~/ui/styles/typography.module.scss';
import { FieldText } from '~/v1/components/fields/text/text';
import { EMAIL_REGEXP } from '~/v1/components/fields/text/text.constants';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';
import { TEST_ID } from '~/v1/constants/testId';

import styles from './newsletter.module.scss';
import { Newsletter_NewsletterFragment } from './query';

enum SubscriptionStatus {
  DEFAULT = 'default',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
  ALREADY_SUBSCRIBED = 'alreadySubscribed',
}

const defaultOnSubmit = (email: string) => {
  return fetch('/api/subscribe', {
    body: JSON.stringify({
      email,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};

const ERROR_TEXTS = {
  [SubscriptionStatus.ERROR]: 'Something went wrong. Check your connection or try again later.',
  [SubscriptionStatus.ALREADY_SUBSCRIBED]: "You're already on our mailing list.",
};

export interface NewsletterProps {
  newsletter: FragmentType<typeof Newsletter_NewsletterFragment>;

  disclaimerLinkHref?: string;
  disclaimerLinkLabel?: string;

  inputLabel?: string;
  actionLabel?: string;

  isInFooter?: boolean;
  noPadding?: boolean;
  onSubmit?: (email: string) => Promise<Response>;

  className?: string;
}

export function Newsletter({
  newsletter: parentNewsletter,
  disclaimerLinkHref = Route.PRIVACY_POLICY,
  disclaimerLinkLabel = "Mellon Foundation's Privacy Policy",
  inputLabel = 'Your email',
  actionLabel = 'Subscribe',
  isInFooter,
  noPadding,
  onSubmit = defaultOnSubmit,
  className,
}: NewsletterProps) {
  const { description, disclaimer } = getFragmentData(
    Newsletter_NewsletterFragment,
    parentNewsletter,
  );

  const id = useId();
  const [status, setStatus] = useState(SubscriptionStatus.DEFAULT);

  const classes = cx(styles.newsletter, className, {
    [styles.newsletterWithoutPadding]: noPadding,
    [styles.newsletterFooter]: isInFooter,
  });

  const submit = (email: string) => {
    setStatus(SubscriptionStatus.LOADING);

    onSubmit(email)
      .then(async res => {
        const { isAlreadySubscribed } = await res.json();
        if (isAlreadySubscribed) {
          setStatus(SubscriptionStatus.ALREADY_SUBSCRIBED);
        } else {
          setStatus(res.ok ? SubscriptionStatus.SUCCESS : SubscriptionStatus.ERROR);
        }
      })
      .catch(() => setStatus(SubscriptionStatus.ERROR));

    trackEvent(Event.NEWSLETTER_SUBMIT);
  };

  return (
    <div className={classes} data-test-id={TEST_ID.NEWSLETTER}>
      {status === SubscriptionStatus.SUCCESS ? (
        <div aria-live="polite">
          <CheckIcon className={styles.checkmarkIcon} />
          <p className={typographyStyles.bodyLarge}>Thank you for subscribing!</p>
          <p className={typographyStyles.bodyLarge}>We&rsquo;ve sent you a confirmation email.</p>
        </div>
      ) : (
        <>
          <p className={cx(typographyStyles.bodySmall, styles.title)}>{description}</p>
          <FieldText
            className={styles.textInput}
            id={id}
            label={inputLabel}
            buttonLabel={actionLabel}
            validationRegEx={EMAIL_REGEXP}
            helperText="Enter a valid email format"
            handleFocus={() => trackEvent(Event.NEWSLETTER_FOCUS)}
            onActionClick={submit}
            isLoading={status === SubscriptionStatus.LOADING}
            isThemed={isInFooter}
            errorText={ERROR_TEXTS[status as keyof typeof ERROR_TEXTS]}
          />

          <p className={cx(typographyStyles.caption, styles.disclaimer)}>
            {disclaimer}
            &nbsp;
            <Link
              href={disclaimerLinkHref}
              className={cx(typographyStyles.caption, styles.disclaimerLink)}
            >
              {disclaimerLinkLabel}
            </Link>
            .
          </p>
        </>
      )}
    </div>
  );
}

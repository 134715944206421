'use client';

import NextLink from 'next/link';
import { Children, useId } from 'react';

import cx from 'classnames';
import { A11y, Keyboard, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button, ButtonVariant } from '~/ui/components/button';
import { Column, Grid, type GridProps } from '~/ui/components/grid';
import { type Breakpoint } from '~/ui/styles/grid';
import typograpyStyles from '~/ui/styles/typography.module.scss';
import { ExpandingArrow } from '~/v1/components/expandingArrow/expandingArrow';
import { Link } from '~/v1/components/link/link';

import styles from './carousel.module.scss';
import { CarouselSize } from './types';

import './swiper.scss';

export interface CarouselProps extends Omit<GridProps, 'overflow'> {
  size: CarouselSize;
  title?: string;
  ctaType?: 'button' | 'link';
  ctaLabel?: string;
  ctaHref?: string;
  cardSize?: Record<Breakpoint, number>;
  children?: React.ReactNode[];
}

export function Carousel({
  size,
  title,
  ctaType = 'button',
  ctaLabel,
  ctaHref,
  children,
  className,
  ...props
}: CarouselProps) {
  const id = useId();

  let cardSize: Record<Breakpoint, number>;
  switch (size) {
    case CarouselSize.GRANTS:
    case CarouselSize.NEWS:
      cardSize = { sm: 3, md: 3, lg: 3 };
      break;

    case CarouselSize.HIGHLIGHT:
      cardSize = { sm: 3, md: 2.5, lg: 2 };
      break;
  }

  return (
    <Grid
      render={<section />}
      aria-roledescription="carousel"
      aria-labelledby={`${id}-title`}
      className={cx(styles.root, className, {
        [styles.news]: size === CarouselSize.NEWS,
        [styles.grants]: size === CarouselSize.GRANTS,
        [styles.highlight]: size === CarouselSize.HIGHLIGHT,
      })}
      {...props}
    >
      <Column sm={5} md={7} lg={8} offsetLeft={{ lg: 1 }}>
        <Grid>
          <Column lg={7} className={styles.header}>
            {title && (
              <h2 className={typograpyStyles.modulesTitle} id={`${id}-title`}>
                {title}
              </h2>
            )}
            <div className={styles.controls}>
              <button id={`${id}-prev`}>
                <ExpandingArrow direction="left" />
              </button>
              <button id={`${id}-next`}>
                <ExpandingArrow direction="right" />
              </button>
            </div>
          </Column>
        </Grid>
        <Grid
          render={
            <Swiper
              modules={[A11y, Keyboard, Navigation]}
              a11y={{
                prevSlideMessage: 'Previous',
                nextSlideMessage: 'Next',
                itemRoleDescriptionMessage: 'slide',
                slideLabelMessage: '',
              }}
              keyboard
              navigation={{
                // We have to use an attribute selector (e.g. [id="xyz"]) instead of
                //   an id selector (e.g. #xyz) because the value provided by useId()
                //   is not valid CSS.
                prevEl: `[id="${id}-prev"]`,
                nextEl: `[id="${id}-next"]`,
              }}
              slidesPerView="auto"
            />
          }
          className={styles.swiper}
        >
          {Children.map(children, (child, index) => (
            <Slide index={index} cardSize={cardSize} key={index}>
              {child}
            </Slide>
          ))}
        </Grid>

        {!!ctaLabel && !!ctaHref && (
          <div className={styles.cta}>
            {ctaType === 'button' ? (
              <Button variant={ButtonVariant.SECONDARY} render={<NextLink href={ctaHref} />}>
                {ctaLabel}
              </Button>
            ) : (
              <Link href={ctaHref} withIcon>
                {ctaLabel}
              </Link>
            )}
          </div>
        )}
      </Column>
    </Grid>
  );
}

// Swiper uses `displayName` to determine whether the children are slides or not. This is a hack to
//  allow us to have <Column /> slides: https://github.com/nolimits4web/swiper/issues/4413#issuecomment-1021387492
function Slide({
  index,
  cardSize,
  children,
}: {
  index: number;
  cardSize: Record<Breakpoint, number>;
  children: React.ReactNode;
}) {
  return (
    <Column
      {...cardSize}
      className={styles.slide}
      style={{
        ['--shade' as never]: `${5 * [0, 1, 2, 1][index % 4]}%`,
      }}
      render={<SwiperSlide />}
    >
      {children}
    </Column>
  );
}
Slide.displayName = 'SwiperSlide';

'use client';

import {
  Children,
  createContext,
  useContext,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Role } from '@ariakit/react';
import { Slot } from '@radix-ui/react-slot';

import { Button, type ButtonProps, ButtonSize, ButtonVariant } from '~/ui/components/button';

const ExpandedContext = createContext<{
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isExpanded: false,
  setIsExpanded: () => null,
});

export interface ExpandableProviderProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Role>, 'id'> {
  /**
   * The text to show when expanding/collapsing the items
   */
  ctaText: [string, string];
  /**
   * An optional toggle for the expandable list. If none is provided, the default toggle will be
   * used.
   */
  toggleProps?: Omit<
    ButtonProps,
    'onClick' | 'variant' | 'size' | 'aria-controls' | 'aria-expanded'
  >;
  children: React.ReactNode;
}

export function ExpandableProvider({
  ctaText,
  toggleProps,
  children,
  ...rest
}: ExpandableProviderProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const listId = useId();

  return (
    <ExpandedContext.Provider value={{ isExpanded, setIsExpanded }}>
      <Role id={listId} {...rest}>
        {children}
      </Role>
      <Button
        onClick={() => setIsExpanded(prev => !prev)}
        variant={ButtonVariant.SECONDARY}
        size={ButtonSize.REGULAR}
        aria-expanded={isExpanded}
        aria-controls={listId}
        {...toggleProps}
      >
        {isExpanded ? ctaText[1] : ctaText[0]}
      </Button>
    </ExpandedContext.Provider>
  );
}

export interface ExpandableContentProps {
  children: React.ReactNode;
}

export function ExpandableContent({ children }: ExpandableContentProps) {
  return Children.map(children, child => <ExpandableContentItem>{child}</ExpandableContentItem>);
}

function ExpandableContentItem({ children }: { children: React.ReactNode }) {
  const ref = useRef<HTMLElement>(null);
  const { isExpanded, setIsExpanded } = useContext(ExpandedContext);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }
    // @ts-expect-error React doesn't support the hidden="until-found" attribute or the beforematch event
    el.hidden = isExpanded ? false : 'onbeforematch' in document.body ? 'until-found' : true;
  }, [isExpanded]);

  useLayoutEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }
    const onBeforeMatch = () => {
      setIsExpanded(true);
    };
    el.addEventListener('beforematch', onBeforeMatch);
    return () => el.removeEventListener('beforematch', onBeforeMatch);
  }, [setIsExpanded]);

  return <Slot ref={ref}>{children}</Slot>;
}

'use client';

import { forwardRef, useId, useLayoutEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { motion } from 'motion/react';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import MinusIcon from '~/ui/assets/icons/minus.svg';
import PlusIcon from '~/ui/assets/icons/plus.svg';
import { Column, Grid, type GridProps } from '~/ui/components/grid';
import typographyStyles from '~/ui/styles/typography.module.scss';
import Markdown from '~/v1/components/markdown/markdown';

import styles from './faq.module.scss';
import { Faq_FaqFragment } from './query';

const MotionColumn = motion.create(Column);

export interface FaqProps extends GridProps {
  data: FragmentType<typeof Faq_FaqFragment>;
}

export const Faq = forwardRef(FaqImpl);
function FaqImpl({ data, ...props }: FaqProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) {
  const { question, answer } = getFragmentData(Faq_FaqFragment, data);

  const ddRef = useRef<HTMLElement>(null);
  const id = useId();
  const [isExpanded, setIsExpanded] = useState(false);

  useLayoutEffect(() => {
    const el = ddRef.current;
    if (!el) {
      return;
    }
    const onBeforeMatch = () => {
      setIsExpanded(true);
    };
    el.addEventListener('beforematch', onBeforeMatch);
    return () => el.removeEventListener('beforematch', onBeforeMatch);
  }, [setIsExpanded]);

  return (
    <Grid ref={forwardedRef} {...props}>
      <Column render={<dt />} className={typographyStyles.bodyLarge}>
        <Grid
          render={<button type="button" />}
          onClick={() => setIsExpanded(prev => !prev)}
          id={`${id}-header`}
          aria-expanded={isExpanded}
          aria-controls={`${id}-content`}
          className={styles.headerButton}
        >
          <Column sm={4} md={5} lg={5} className={styles.headerTextColumn}>
            {question}
          </Column>
          <Column sm={1} md={1} lg={1} offsetLeft={{ md: 1, lg: 1 }} className={styles.iconWrapper}>
            {isExpanded ? (
              <MinusIcon className={styles.icon} />
            ) : (
              <PlusIcon className={styles.icon} />
            )}
          </Column>
        </Grid>
      </Column>

      <MotionColumn
        initial={{ overflow: 'hidden' }}
        animate={{ height: isExpanded ? 'auto' : 0, marginTop: isExpanded ? 32 : 0 }}
        transition={{ duration: 0.2, delay: isExpanded ? 0 : 0.2 }}
        className={cx(styles.content, {
          [styles.isExpanded]: isExpanded,
        })}
        sm={5}
        md={5}
        lg={5}
        render={<dd ref={ddRef} />}
        onAnimationStart={() => {
          if (!isExpanded) {
            return;
          }
          const el = ddRef.current;
          if (!el) {
            return;
          }
          el.hidden = false;
        }}
        onAnimationEnd={() => {
          if (isExpanded) {
            return;
          }
          const el = ddRef.current;
          if (!el) {
            return;
          }
          // @ts-expect-error React doesn't support the hidden="until-found" attribute or the beforematch event
          el.hidden = 'onbeforematch' in document.body ? 'until-found' : true;
        }}
      >
        <motion.div
          id={`${id}-content`}
          aria-labelledby={`${id}-header`}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.2, delay: isExpanded ? 0.2 : 0 }}
          animate={{ opacity: isExpanded ? 1 : 0 }}
        >
          <Markdown className={typographyStyles.paragraph}>{answer ?? ''}</Markdown>
        </motion.div>
      </MotionColumn>
    </Grid>
  );
}
